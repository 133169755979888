.aboutUs {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

@media screen and (max-width: 768px) {
  .aboutUs {
    width: 85%;
  }
}
p {
  text-align: justify;
}
