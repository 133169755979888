form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 30px;
}

label {
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  width: 100%;
  color: #622f0a;
  font-weight: bold;
  font-family: "Gabriola";
  font-size: 20px;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="datetime-local"],
input[type="number"] {
  height: 20px;
  width: 100%;
  border: 4px solid #622f0a;
  border-radius: 8px;
  padding: 10px;
  padding-inline: 0px;
  margin-top: 5px;
  font-size: 16px;
}

button[type="submit"] {
  height: 60px;
  width: 80%;
  background-color: #622f0a;
  color: white;
  font-size: large;
  border: 8px solid rgb(250, 210, 162);
  border-radius: 8px;
  margin-top: 12px;
  margin-inline: auto;
}

@media screen and (max-width: 410px) {
  form {
    min-width: 70%;
    max-width: 95%;
  }
  .dateAndTime {
    min-width: 100%;
    max-width: 100%;
  }

  input[type="text"],
  input[type="email"],
  input[type="datetime-local"],
  input[type="number"],
  button[type="submit"] {
    width: 100%;
  }
  .react-datepicker {
    width: 250px !important;
  }
}

.custom-datepicker-input {
  position: relative;
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid #ccc;
  padding: 8px 40px 8px 8px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.custom-datepicker-arrow {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  color: #622f0a;
}
.dateAndTimeLabel {
  margin: 0;
  padding: 0;
}
.dateAndTime {
  margin-bottom: 4px;
}
.custom-datepicker-icon {
  font-size: 46px;
}

.success {
  text-align: center;
  margin: auto;
  color: #622f0a;
  font-weight: bold;
  font-family: "Gabriola";
  font-size: 20px;
}
