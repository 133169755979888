.book_now_link {
  margin-top: 12px;
}
.book_now_icon {
  display: inline-flex;
  background-color: #fffde4;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  box-shadow: 0 0 24px #622f0a;
  align-items: center;
  justify-content: center;

  z-index: 3;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Verdana, sans-serif;
}

@media screen and (max-height: 780px) {
  .book_now_link {
    margin-top: 2px;
  }
  .book_now_icon {
    height: 70px;
    width: 70px;
  }
}

.book_now_icon:hover {
  box-shadow: 0 0 0 #622f0a;
  transform: scale(1.1);
  user-select: none;
  cursor: pointer;
}
.book_now_icon:active {
  box-shadow: 0 0 0 #622f0a;
  transform: scale(0.9);
  user-select: none;
}

svg {
  color: #622f0a;
  text-decoration: #622f0a;
}
textPath {
  color: #622f0a;
}

.table_icon {
  position: absolute;
  top: 2.5em;
  animation: spin 6s linear infinite;
  flex-grow: 2;
  margin-bottom: 0px;
  z-index: 0;
}

@keyframes spin {
  0%,
  20%,
  80%,
  100% {
    transform: rotate(0deg);
  }
  5%,
  85% {
    transform: rotate(15deg);
  }
  15%,
  95% {
    transform: rotate(-15deg);
  }
}
