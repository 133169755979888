.our-news {
  margin-top: 30px;
  margin-bottom: 0;
  overflow: hidden;
  font-family: "Gabriola";
  z-index: -1;
}

@media screen and (max-height: 750px) {
  .our-news {
    margin-top: 3px;
  }

  .our-news li {
    font-size: 20px !important;
  }
}

.our-news {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  animation: scroll 20s linear infinite;
  width: max-content;
  animation-fill-mode: forwards;
}

.our-news {
  font-size: 30px;
  margin-right: 50px;
  white-space: nowrap;
}

@keyframes scroll {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(-100vw);
  }
}
