.footer {
  background-color: #622f0a;
  color: rgb(250, 210, 162);
  padding: 0px;
  text-align: center;
  width: 100%;

  font-size: small;
}
.footer_content {
  margin-top: 0px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 10px;
}
.footer_content_left {
  max-width: calc(50% - 10px);
  padding-left: 5px;
  flex-basis: 50%;
}
.footer_content_right {
  max-width: calc(50% - 10px);
  padding-right: 5px;
  flex-basis: 50%;
}
.footer_content_bottom {
  order: 3;
  flex-basis: 100%;
}
.footer_titles {
  font-family: "Gabriola";
  font-size: 24px;
  margin-bottom: 0;
}

.footer_buttons:hover {
  scale: 1.3;
  text-decoration: none;
}
.footer_contact_icons {
  justify-content: center;
  align-items: center;
  display: flex;
}
.footer_buttons {
  margin: 0px 10px;
}
