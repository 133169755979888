form {
  max-width: 300px;
  margin: auto auto;
}
label {
  display: flex;
  flex-direction: column;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 2px;
  width: 100%;
  color: #622f0a;
  font-weight: bold;
  font-family: "Gabriola";
  font-size: 20px;
}

.seasonalSwitch {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.toggleSwitch {
  margin-right: 10px;
}

.toggleSwitch .react-switch-bg {
  width: 40px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
}

.working-hours-list {
  list-style: none;
  padding: 0;
}
.checkbox {
  display: flex;
  flex-direction: row;
}

.working-hours-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2px;
}

.day-container {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.day-container span {
  margin-right: 5px;
}
.news {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0px;
  margin-inline: 10px;
}
.edit-button {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin-right: 5px;
  font-size: 16px;
}
