.construction-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #f7f7f7;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .construction-icon {
    font-size: 3rem;
    margin-bottom: 1rem;
    color: #888;
  }

  .social-icons {
    display: flex;
    justify-content: space-around;
    
  }