.home_content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: visible;
}
.activeLink:active p {
  text-decoration: none;
  color: brown;
}
.carousel_working_hours_container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
}

@media screen and (min-width: 768px) {
  .cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }
}
