.custom-card {
  border-radius: 4px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 300px;
  margin: auto;
  margin-top: 10px;
}

@media screen and (min-width: 768px) {
  .custom-card {
    margin: 20px;
  }
}

.custom-card-image {
  position: relative;
}

.custom-card-image img {
  display: block;
  width: 100%;
  height: 250px;
  object-fit: cover;
}

.custom-card-title-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(250, 210, 162, 0.6);
  padding: 8px;
}

.custom-card-title {
  font-size: 18px;
  color: #622f0a;
  margin: 0;
}

.custom-card-content {
  position: absolute;
  bottom: 0;
  left: -6px;

  width: 100%;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.6);
}

.custom-card-text {
  font-size: 14px;
  margin: 0;
  font-weight: bolder;
}
