.social_media {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.social_media .icons {
  margin-inline: 5px;
}
.social_media .icons:hover {
  transform: scale(1.2);
  user-select: none;
  cursor: pointer;
}
