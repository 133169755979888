.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background-color: rgb(250, 210, 162);
  padding-block: 4px;
  padding-inline: 16px;
  box-shadow: 0 0 10px 0 #622f0a;
  overflow: hidden;
}

.logo {
  max-width: 10%;
  min-width: 130px;
}

@media screen and (max-width: 411px) {
  .header {
    justify-content: space-around;
  }
}
